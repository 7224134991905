import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticGridComponent } from './components/statistic-grid/statistic-grid.component';
import { TranslocoModule } from '@jsverse/transloco';
import { GroupsComponent } from './components/groups/groups.component';
import { GuiModule } from '../../gui/gui.module';
import { DynamicGridModule } from '../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

const ENTRY_COMPONENTS = [GroupsComponent];

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule, CommonModule, TranslocoModule];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  exports: [GroupsComponent],
  imports: [...ENTRY_MODULES, DragDropModule]
})
export class StatisticModule {}
