import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { groupsPopupAnimation } from '../../../../ui/animations';
import { GroupItem, GroupsConfig } from '../../../../core/interfaces';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'adxad-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  animations: [groupsPopupAnimation]
})
export class GroupsComponent {
  public prefix = 'g_';
  public showPopup = false;
  public config = {
    data: []
  } as GroupsConfig;

  @Input()
  disabled: boolean;

  @Output()
  changeGroups = new EventEmitter<GroupItem[]>();

  @Output()
  changeGroupsOrder = new EventEmitter<GroupItem[]>();

  /**
   * e.stopPropagation() prevent the click event inside the component from bubbling to parent elements , otherwise outClickHandler will be trigger
   * @param event
   */
  @HostListener('click', ['$event']) inClick(event: MouseEvent) {
    event.stopPropagation();
  }

  @HostListener('document:click') outClickHandler() {
    this.showPopup = false;
  }

  constructor(private queryParamsService: QueryParamsService) {}

  /**
   * @return {GroupItem[]} list of selected params
   */
  public get resultParams(): any {
    return this.config.data
      .filter(x => x.checked)
      .sort((a, b) => {
        return +a.order - +b.order;
      });
  }

  /**
   * Set groups list
   * Set default checked values
   * Checked query params
   * @param {GroupsConfig} config
   */
  public setConfig(config: any): void {
    this.config = config;
    this.config.data.forEach(x => (x.checked = this.config.default.indexOf(x.id) !== -1));

    /** Check values in url */
    const paramsInUrl = this.queryParamsService.get(this.prefix);
    const setFromUrl = !!Object.keys(paramsInUrl).length;

    this.config.data.forEach((x: GroupItem, i: number) => {
      x.order = i.toString();
      const prefixId = this.prefix + x.id;

      if (setFromUrl) {
        /** Set params from url */
        x.checked = !!paramsInUrl[prefixId];
        x.order = paramsInUrl[prefixId];
      } else {
        /** Add default params in url */
        if (x.checked) {
          this.queryParamsService.add(prefixId, x.order);
        }
      }
    });
  }

  /**
   * Set default groups
   * Set query params
   */
  public resetConfig(): void {
    this.config.data.forEach(x => {
      x.checked = this.config.default.indexOf(x.id) !== -1;
      if (x.checked) {
        this.queryParamsService.add(this.prefix + x.id, x.order);
      } else {
        this.queryParamsService.remove(this.prefix + x.id);
      }
    });
  }

  /**
   * @return flag of disable 'Clear all' btn
   */
  get isDisabledClearBtn(): boolean {
    return this.selectedParams.length === 1;
  }

  /**
   * @return flag of disable 'Select all' btn
   */
  get isDisabledSelectBtn(): boolean {
    return !this.availableParams.length;
  }

  /**
   * @return list of group items for list of selected params
   */
  get selectedParams(): GroupItem[] {
    return this.config.data.filter(x => x.checked);
  }

  /**
   * @return list of group items for list of available params
   */
  get availableParams(): GroupItem[] {
    return this.config.data.filter(x => !x.checked);
  }

  /**
   * Toggle params if is not last selected param
   * Toggle query param
   * Emit changeGroups event
   * Set new order
   * @param {GroupItem} param
   */
  toggleParam(param: GroupItem): void {
    if (this.disabled || (param.checked && this.selectedParams.length === 1)) {
      return;
    }
    const prefixId = this.prefix + param.id;
    param.checked = !param.checked;

    this.checkOrder();
    if (param.checked) {
      this.queryParamsService.add(prefixId, param.order);
    } else {
      this.queryParamsService.remove(prefixId);
    }

    this.changeGroups.emit(this.resultParams);
  }

  /**
   * Clear all params besides first
   * Remove params from resultParams
   * Remove query params
   * Set new order
   * Emit changeGroups event
   */
  clearAll(): void {
    if (this.disabled || this.isDisabledClearBtn) {
      return;
    }

    const startIndex = this.config.data.findIndex(x => x.checked);
    this.config.data.forEach((x: GroupItem, i: number) => {
      if (i > startIndex && x.checked) {
        x.checked = false;
        this.resultParams.splice(this.resultParams.indexOf(x), 1);
        this.queryParamsService.remove(this.prefix + x.id);
      }
    });
    this.checkOrder();
    this.changeGroups.emit(this.resultParams);
  }

  /**
   * Select all params
   * Push params in resultParams
   * Add query params
   * Set new order
   * Emit changeGroups event
   */
  selectAll(): void {
    if (this.disabled || this.isDisabledSelectBtn) {
      return;
    }

    this.config.data.forEach((x: GroupItem) => {
      if (!x.checked) {
        x.checked = true;
        this.resultParams.push(x);
        this.queryParamsService.add(this.prefix + x.id, x.order);
      }
    });
    this.checkOrder();
    this.changeGroups.emit(this.resultParams);
  }

  /**
   * Toggle popup
   */
  togglePopup(): void {
    if (this.disabled) {
      return;
    }

    this.showPopup = !this.showPopup;
  }

  /**
   * Horizontal scroll chips
   * @param {WheelEvent} e
   */
  scrollParams(e: WheelEvent): void {
    if (this.disabled) {
      return;
    }
    e.preventDefault();
    (e.target as HTMLElement).closest('.adxad-groups__params-wrapper').scrollLeft += e.deltaY / 2;
  }

  /**
   * Drag & drop params
   * Set new order
   * Change query params
   * Emit changeGroups event
   */
  dndParams(e: CdkDragDrop<GroupItem[]>): void {
    const result = [...this.resultParams];
    moveItemInArray(result, e.previousIndex, e.currentIndex);
    result.forEach((x: GroupItem, i: number) => {
      x.order = i.toString();
    });

    this.resultParams.forEach(x => this.queryParamsService.add(this.prefix + x.id, x.order));
    this.changeGroupsOrder.emit(this.resultParams);
  }

  /**
   * Set new order in selected params
   */
  checkOrder(): void {
    this.resultParams.forEach((x: GroupItem, i: number) => {
      x.order = i.toString();
    });
  }

  /**
   * @return {string} checked groups in string
   */
  public get result(): string {
    return this.resultParams.map(x => x.id).toString();
  }
}
